import React, { useEffect, useState } from 'react';
import { Link, useI18next } from "gatsby-plugin-react-i18next";

import { RegisterLayout } from '../../components/register-layout.component';
import { fetchVerificationInformation, resendEmail } from '../../services/users.service';
import { setUser } from '../../states/authentication.slice';
import { useDispatch } from 'react-redux';

export default function Home() {

    const [information, setInformation] = useState({});
    const [isResent, setIsResent] = useState(false);
    const { t, navigate } = useI18next();
    const dispatch = useDispatch();

    useEffect(() => {
        const interval = setInterval(() => {
            fetchVerificationInformation()
                .then(response => {
                    if (response.data.emailVerified) {
                        dispatch(setUser(response.data.user));
                        navigate('/register');
                    }
                    setInformation(response.data);
                })
                .catch(e => console.error(e));
        }, 2000);
        return () => clearInterval(interval);
    }, []);

    const handleResendEmail = () => {
        try {
            const response = resendEmail({ emailAddress: information.verificationEmailAddress });
            setIsResent(true);
        } catch (error) {
            console.error('email resend');  
        }
    }

    // setInterval(checkVerification, 5 * 1000)

    return <RegisterLayout disableSecureLayout={true} title={t('Email confirmation')} className="create-account verification-email" backLocation="/">
        <div className="container">
            <img src={'/img/svgs/confirm-email.svg'} alt='confirm email' />
            <div className="section section--verify-email">
                <h1 className="t-h2">{t('Confirm your email address')}</h1>
                <p className="t-sans t-p1 t-grey7">{t('Almost done, a confirmation email has been sent to the following email address')}</p>
                <p className="t-sans t-p1 t-grey7">{information.verificationEmailAddress}</p>
                <p className="t-sans t-p1 t-grey7">{t('Please click the link in the email to complete your registration.')}</p>
                
                {isResent ? (
                    <p className="t-sans t-p1">{t('Verification email has re sent, please check out your email.')}</p>
                    ) : (
                    <button type="button" className="t-link t-bold t-green" onClick={handleResendEmail}>{t('Re-send Email')}</button>
                )}

            </div>
        </div>
        <div className="section">
            <div className="tip">
                <p className="t-sans t-p1 t-grey9">メールが届かない場合</p>
                <p className="t-sans t-p1 t-grey7 tip__detail">
                ※no-reply-pcr@sf-housenka.orgからのメールの受信許可をお願いいたします。<br/>
                ※メールが「迷惑メールフォルダ」に入っていることがあります。ご確認ください。
                </p>
            </div>
        </div>
    </RegisterLayout>;
}
